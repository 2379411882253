/*
 * @Description:
 * @Author: xiaoq
 * @Date: 2024-03-21 12:09:57
 * @LastEditTime: 2024-08-13 19:34:19
 * @LastEditors: xiaoq
 */
import Vue from "vue";
import App from "./App.vue";
import "./global.css";
import VueRouter from "vue-router";
import router from "./page/router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Antd); //全局注册
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
